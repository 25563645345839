import React from 'react';
import { Route } from 'react-router';
import {
  Admin as RAAdmin,
  AppBar as RAAppBar,
  CustomRoutes,
  Layout as RALayout,
  Resource,
  UserMenu as RAUserMenu,
} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

import { QuestCreate } from '../QuestCreate';
import { QuestList } from '../QuestList';
import { QuestEdit } from '../QuestEdit';
import { LogoutButton } from '../LogoutButton';
import { LoginPage } from '../LoginPage';
import { ADMIN_PANEL_SERVER_URL } from '../App.config';
import { QuestShow } from '../QuestShow';
import { Dashboard } from '../Dashboard';
import { ForbiddenPage } from '../ForbiddenPage';
import { myTheme } from './theme';
import useAuthProvider from './useAuthProvider';
import { AdminCreate } from '../AdminCreate';
import { AdminList } from '../AdminList';
import { checkPermission, httpClient } from './Admin.utilities';
import { AdminAccessRightListEnum } from '../Shared/Admin.types';
import { AdminEdit } from '../AdminEdit';
import { AdminLogList } from '../AdminLogList';
import { AdminLogShow } from '../AdminLogShow';
import { PartnerList } from '../PartnerList';
import { PartnerShow } from '../PartnerShow';
import { PartnerCreate } from '../PartnerCreate';
import { PartnerEdit } from '../PartnerEdit';
import { GameList } from '../GameList';

const dataProvider = simpleRestProvider(ADMIN_PANEL_SERVER_URL, httpClient);

const UserMenu: React.FC = () => {
  return (
    <RAUserMenu>
      <LogoutButton />
    </RAUserMenu>
  );
};

const AppBar: React.FC = () => {
  return <RAAppBar userMenu={<UserMenu />} />;
};

const Layout: React.FC = (props) => <RALayout {...props} appBar={AppBar} />;

const Admin: React.FC = () => {
  const authProvider = useAuthProvider();

  return (
    <RAAdmin
      theme={myTheme}
      authProvider={authProvider}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      disableTelemetry
      layout={Layout}
      loginPage={LoginPage}
      requireAuth
    >
      {(permissions: AdminAccessRightListEnum[]): React.ReactNode => (
        <>
          <CustomRoutes>
            <Route path="/forbidden" element={<ForbiddenPage />} />
          </CustomRoutes>
          {checkPermission(
            permissions,
            [
              AdminAccessRightListEnum.ManageQuests,
              AdminAccessRightListEnum.ReadQuests,
              AdminAccessRightListEnum.CreateQuests,
              AdminAccessRightListEnum.UpdateQuests,
            ],
            true
          ) && (
            <Resource
              name="quests"
              list={QuestList}
              create={
                checkPermission(permissions, [
                  AdminAccessRightListEnum.CreateQuests,
                ])
                  ? QuestCreate
                  : undefined
              }
              edit={QuestEdit}
              show={QuestShow}
              icon={ListAltRoundedIcon}
            />
          )}
          {checkPermission(permissions, [
            AdminAccessRightListEnum.ManageAdmins,
          ]) && (
            <Resource
              name="admins"
              list={AdminList}
              create={AdminCreate}
              edit={AdminEdit}
              icon={PeopleAltRoundedIcon}
            />
          )}
          {checkPermission(permissions, [
            AdminAccessRightListEnum.ManageAdminlogs,
          ]) && (
            <Resource
              name="adminlogs"
              list={AdminLogList}
              show={AdminLogShow}
              icon={RecentActorsRoundedIcon}
              options={{
                label: 'Admin Logs',
              }}
            />
          )}
          {checkPermission(
            permissions,
            [
              AdminAccessRightListEnum.ManagePartners,
              AdminAccessRightListEnum.ReadPartners,
              AdminAccessRightListEnum.CreatePartners,
              AdminAccessRightListEnum.UpdatePartners,
            ],
            true
          ) && (
            <Resource
              name="partners"
              list={PartnerList}
              show={PartnerShow}
              edit={
                checkPermission(permissions, [
                  AdminAccessRightListEnum.UpdatePartners,
                ])
                  ? PartnerEdit
                  : undefined
              }
              create={
                checkPermission(permissions, [
                  AdminAccessRightListEnum.CreatePartners,
                ])
                  ? PartnerCreate
                  : undefined
              }
              icon={HandshakeIcon}
            />
          )}
          {checkPermission(
            permissions,
            [
              AdminAccessRightListEnum.ManageGames,
              AdminAccessRightListEnum.ReadGames,
            ],
            true
          ) && (
            <Resource name="games" list={GameList} icon={SportsEsportsIcon} />
          )}
        </>
      )}
    </RAAdmin>
  );
};

export default Admin;
