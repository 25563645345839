import styled from 'styled-components';
import { Card, CardProps } from '@mui/material';

const StyledCard = styled(Card)<
  CardProps & {
    isSmallDevice: boolean;
  }
>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 100px;

  ${({ isSmallDevice }) =>
    isSmallDevice &&
    `
     padding: 10px 20px;
    `};
    
    & > * {
      display: flex;
      flex-direction: row;
      align-items: center;

      .RaLabeled-label {
        margin-right: 5px;
        margin-bottom: 0;
      }
      
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }

  img {
    width: 50px !important;
    height: 50px !important;

    ${({ isSmallDevice }) =>
      isSmallDevice &&
      `
      width: 30px !important;
      height: 30px !important;
    `};
`;

export { StyledCard };
