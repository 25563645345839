import React, { FC } from 'react';
import {
  required,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from 'react-admin';
import {
  REQUIRED_MESSAGE,
  SELECT_REQUIRED_MESSAGE,
} from '../AdminForm/AdminForm.utilities';
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  ImageButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInput,
  RichTextInputToolbar,
  useTiptapEditor,
} from 'ra-input-rich-text';
import { ToggleButton } from '@mui/material';
import Remove from '@mui/icons-material/Remove';

const MyRichTextInputToolbar: React.FC<any> = ({ size, ...props }) => {
  const editor = useTiptapEditor();

  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect size={size} />
      <ColorButtons size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <ImageButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
      <ToggleButton
        aria-label="Add an horizontal rule"
        title="Add an horizontal rule"
        value="left"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        selected={editor && editor.isActive('horizontalRule')}
        size={size}
      >
        <Remove fontSize="inherit" />
      </ToggleButton>
    </RichTextInputToolbar>
  );
};

const PartnerForm: FC<Omit<SimpleFormProps, 'children'>> = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput
        source="name"
        validate={[required(REQUIRED_MESSAGE)]}
        fullWidth
        label="Name"
      />
      <TextInput
        source="description"
        validate={[required(REQUIRED_MESSAGE)]}
        label="Description"
        fullWidth
      />
      <TextInput
        source="logo"
        validate={[required(REQUIRED_MESSAGE)]}
        label="Logo"
        fullWidth
      />
      <TextInput
        source="website"
        validate={[required(REQUIRED_MESSAGE)]}
        label="Website"
        fullWidth
      />
      <SelectInput
        source="category"
        label="Category"
        choices={[{ id: 'none', name: 'None' }]}
        validate={required(SELECT_REQUIRED_MESSAGE)}
        fullWidth
      />
      <SelectInput
        source="type"
        label="Type"
        choices={[
          { id: 'other', name: 'Other' },
          { id: 'galactic', name: 'Galactic' },
        ]}
        validate={required(SELECT_REQUIRED_MESSAGE)}
        fullWidth
      />
      <SelectInput
        source="status"
        label="Status"
        choices={[
          { id: 0, name: 'Passive' },
          { id: 1, name: 'Active' },
        ]}
        validate={required(SELECT_REQUIRED_MESSAGE)}
        fullWidth
      />
      <RichTextInput
        source="financialInfo"
        label="Financial Info"
        toolbar={<MyRichTextInputToolbar size="large" />}
        defaultValue=""
      />
      <RichTextInput
        source="contactInfo"
        label="Contact Info"
        toolbar={<MyRichTextInputToolbar size="large" />}
        defaultValue=""
      />
    </SimpleForm>
  );
};

export default PartnerForm;
