import styled from 'styled-components';
import { ImageField, ImageFieldProps } from 'react-admin';

const StyledImageField = styled(ImageField)<
  ImageFieldProps & { isSmallDevice: boolean }
>`
  img {
    width: 50px !important;
    height: 50px !important;

    ${({ isSmallDevice }) =>
      isSmallDevice &&
      `
      width: 30px !important;
      height: 30px !important;
    `};
  }
`;

export { StyledImageField };
